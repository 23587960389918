<template>
  <div class="organization">
    <div class="breadcrumb">
      <el-breadcrumb separator="/">
        <el-breadcrumb-item :to="{ path: '/archivRet' }">档案记录</el-breadcrumb-item>
        <el-breadcrumb-item>记录详情</el-breadcrumb-item>
      </el-breadcrumb>
    </div>
    
    <div class="table-tree">
      <el-tabs v-model="activeName" @tab-click="handleClick" tab-position="left" style="width: 100%;height: 100%;">
        <el-tab-pane label="设备信息" name="device">
          <device />
        </el-tab-pane>
        <el-tab-pane label="巡检档案" name="patrol">
          <patrol />
        </el-tab-pane>
        <el-tab-pane label="点检档案" name="check">
          <check />
        </el-tab-pane>
        <el-tab-pane label="检修档案" name="overhaul">
          <overhaul />
        </el-tab-pane>
        <el-tab-pane label="润滑档案" name="lubrication">
          <lubrication />
        </el-tab-pane>
      </el-tabs>
    </div>
  </div>
</template>

<script>
import device from './device/index.vue'
import patrol from './patrol/index.vue'
import check from './check/index.vue'
import overhaul from './overhaul/index.vue'
import lubrication from './lubrication/index.vue'

export default {
  components: {
    device,
    patrol,
    check,
    overhaul,
    lubrication
  },
  data() {
    return {
      activeName: 'device'
    }
  },
  methods: {
    handleClick(tab){
      this.activeName = tab.name
    }
  },
  mounted() {
    if(sessionStorage.getItem('active')){
      this.activeName = sessionStorage.getItem('active')
    }else{
      this.activeName = 'device'
    }
  }
}
</script>

<style lang="less" scoped>
.breadcrumb {
  padding: 15px 10px;
  .el-breadcrumb {
    font-size: 18px;
  }
}
.btn {
  float: right;
}
/deep/.el-tabs--left, .el-tabs--right{
  overflow: visible;
}
/deep/.el-tabs__item {
  padding: 0 40px;
  height: 50px;
  line-height: 50px;
  font-size: 16px;
}
.table-tree {
  margin-top: 20px;
  display: flex;
  height: ~'calc(100% - 56px)';
}
</style>

<style lang="less">
.organization {
  .el-tree-node__content {
    height: 40px;
  }
}
</style>