<template>
  <div class="mains" v-loading="loading" element-loading-spinner="el-icon-loading">
    <div class="form-box">
      <el-form :model="form" ref="ruleForm" label-width="100px">
        <el-row :gutter="20">
          <el-col :span="12">
            <el-form-item label="设备别名：" prop="name">{{ form.alias }}</el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="设施位置：" prop="name">{{ form.facilityName }}</el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="20">
          <el-col :span="12">
            <el-form-item label="设备识别号：" prop="name">{{ form.ein }}</el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="巡视点名称：" prop="name">{{ form.patrolName }}</el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="20">
          <el-col :span="12">
            <el-form-item label="状态：" prop="name">
              {{ form.deviceStatus==1 ? '正常' 
              : form.deviceStatus==2 ? '异常' 
              : form.deviceStatus==3 ? '未知' 
              : form.deviceStatus==4 ? '停用' 
              : form.deviceStatus==5 ? '报废' 
              : '' }}
            </el-form-item>
          </el-col>
        </el-row>
        <div class="table-list">
          <el-table :data="tableData" style="width: 100%">
            <el-table-column label="序号" type="index" width="50"></el-table-column>
            <el-table-column prop="taskCode" label="润滑代号"></el-table-column>
            <el-table-column label="润滑人">
              <template slot-scope="scope">
                <span v-for="(item, index) in scope.row.staffNames" :key="index">
                  {{ scope.row.staffNames.length-1 > index ? item+'、' : item }}
                </span>
              </template>
            </el-table-column>
            <el-table-column label="时间段" width="160">
              <template slot-scope="scope">
                {{ scope.row.startTime ? dateSecond(scope.row.startTime) : '00:00:00' }} - {{ scope.row.endTime ? dateSecond(scope.row.endTime) : '00:00:00' }}
              </template>
            </el-table-column>
            <el-table-column label="润滑周期">
              <template slot-scope="scope">
                {{ scope.row.cycle==0 ? '星期配置' : '自定义' }}
              </template>
            </el-table-column>
            <el-table-column label="任务状态">
              <template slot-scope="scope">
                <el-tag type="success" v-if="scope.row.taskStatus==1">已完成</el-tag>
                <el-tag type="danger" v-if="scope.row.taskStatus==2">已遗漏</el-tag>
              </template>
            </el-table-column>
            <el-table-column label="提交人">
              <template slot-scope="scope">
                {{ scope.row.submitter ? scope.row.submitter:'-' }}
              </template>
            </el-table-column>
            <el-table-column label="提交时间" width="160">
              <template slot-scope="scope">
                {{ scope.row.completeTime ? dayjs(scope.row.completeTime).format('YYYY-MM-DD HH:mm:ss'):'-' }}
              </template>
            </el-table-column>
            <el-table-column label="操作" width="150">
              <template slot-scope="scope">
                <el-dropdown placement="bottom-start" trigger="click">
                  <span @click.stop="">
                    <span class="el-dropdown-link">
                    操作<i class="el-icon-arrow-down"></i>
                    </span>
                  </span>
                  <el-dropdown-menu slot="dropdown">
                    <el-dropdown-item><div @click="details(scope.row)">详情</div></el-dropdown-item>
                  </el-dropdown-menu>
                </el-dropdown>
              </template>
            </el-table-column>
          </el-table>
          <page :size="size" :page="page" :total="total" v-on:sizeChange="sizeChange" v-on:pageChange="pageChange"></page>
        </div>
      </el-form>
    </div>
  </div>
</template>

<script>
import page from '@/components/page.vue'
import dayjs from 'dayjs';
export default {
  components: {
    page
  },
  data() {
    return {
      form: {},
      row: {},
      loading: false,
      size: 50,
      page: 1,
      total: 0,
      tableData: []
    };
  },
  methods: {
    dayjs,
    dateSecond(val) {
      let s = val % 60
      let _s = val - s

      let m = (_s / 60) % 60

      let h = (_s / 60 - m) / 60
      return `${h>9 ? h:'0'+h}:${m>9 ? m:'0'+m}:${s>9 ? s:'0'+s}`
    },
    details(row) {
      this.$router.push({ name: 'aLubeDetail', params: {row: row}})
    },
    loadData() {
      let patrolDeviceId = this.row ? this.row.patrolDeviceId : sessionStorage.getItem('patrolDeviceId')
      this.$ajax.post("archivesDevice", {
        pageNum: this.page,
        pageSize: this.size,
        patrolDeviceId: parseInt(patrolDeviceId)
      }).then((res) => {
        this.form = res.data;
      });
    },
    pageChange(val) {
      this.page = val
      sessionStorage.setItem('currentPage', val)
      this.loadTableData()
    },
    sizeChange(val) {
      this.size = val
      sessionStorage.setItem('currentSize', val)
      this.loadTableData()
    },
    getLastPage() {
      this.page = Number(sessionStorage.getItem('currentPage')) || 1
    },
    getSize() {
      this.size = Number(sessionStorage.getItem('currentSize')) || 50
    },
    loadTableData() {
      let patrolDeviceId = this.row ? this.row.patrolDeviceId : sessionStorage.getItem('patrolDeviceId')
      this.loading = true
      this.$ajax.post('archivesLube', {
        pageNum: this.page,
        pageSize: this.size,
        patrolDeviceId: parseInt(patrolDeviceId)
      }).then(res => {
        this.tableData = res.data
        this.total = res.total
        this.loading = false
      }).catch(err=>{
        this.loading = false
      })
    },
  },
  mounted() {
    if (!sessionStorage.getItem('patrolDeviceId')) {
      this.$router.push("/archivRet");
      return;
    }
    this.row = this.$route.params.row
    this.getLastPage()
    this.getSize()
    this.loadData()
    this.loadTableData()
  },
  destroyed() {
    // sessionStorage.removeItem('currentPage')
    // sessionStorage.removeItem('currentSize')
  }
};
</script>

<style lang="less" scoped>
.mains {
  height: 80%;
}
/deep/.el-loading-spinner .el-icon-loading{
  font-size: 50px;
}
.form-box {
  padding-top: 5px;
  max-width: 1000px;
}
</style>
<style lang="less">
</style>
