<template>
  <div class="mains" v-loading="loading" element-loading-spinner="el-icon-loading">
    <div class="form-box">
      <el-form :model="form" ref="ruleForm" label-width="100px">
        <el-row :gutter="20">
          <el-col :span="12">
            <el-form-item label="设备别名：" prop="name">{{ form.alias }}</el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="设施位置：" prop="name">{{ form.facilityName }}</el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="20">
          <el-col :span="12">
            <el-form-item label="设备识别号：" prop="name">{{ form.ein }}</el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="巡视点名称：" prop="name">{{ form.patrolName }}</el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="20">
          <el-col :span="12">
            <el-form-item label="状态：" prop="name">
              {{ form.deviceStatus==1 ? '正常' 
              : form.deviceStatus==2 ? '异常' 
              : form.deviceStatus==3 ? '未知' 
              : form.deviceStatus==4 ? '停用' 
              : form.deviceStatus==5 ? '报废' 
              : '' }}
            </el-form-item>
          </el-col>
        </el-row>
        <div class="form-title">基本信息</div>
        <el-row :gutter="20">
          <el-col :span="12">
            <el-form-item label="设备名称：" prop="name">{{ form.baseInfo.deviceName }}</el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="设备编码：" prop="name">{{ form.baseInfo.materialsCode }}</el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="20">
          <el-col :span="12">
            <el-form-item label="设备型号：" prop="name">{{ form.baseInfo.model }}</el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="规格参数：" prop="name">{{ form.baseInfo.specification }}</el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="20">
          <el-col :span="12">
            <el-form-item label="生产厂家：" prop="name">{{ form.baseInfo.factory }}</el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="设备数量：" prop="name">{{ form.baseInfo.count }}</el-form-item>
          </el-col>
        </el-row>
        <div class="form-title">使用信息</div>
        <el-row :gutter="20">
          <el-col :span="12">
            <el-form-item label="设备分类：" prop="name">{{ form.useInfo.deviceTypeName }}</el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="负责人：" prop="name">{{ form.useInfo.staffName }}</el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="20">
          <el-col :span="12">
            <el-form-item label="所属部门：" prop="name">{{ form.useInfo.departmentName }}</el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="投入使用时间" prop="name">
              {{ form.useInfo.applyTime ? dayjs(form.useInfo.applyTime).format('YYYY-MM-DD'):'' }}
            </el-form-item>
          </el-col>
        </el-row>
        <div class="form-title">设备图片</div>
        <div class="img-box">
          <el-image 
            class="images"
            v-for="(item,index) in form.fileUrls"
            :key="index"
            :src="item"
            :preview-src-list="[item]">
          </el-image>
        </div>
      </el-form>
    </div>
  </div>
</template>

<script>
import dayjs from 'dayjs';

export default {
  data() {
    return {
      size: 50,
      page: 1,
      total: 0,
      form: {
        baseInfo:{},
        useInfo:{}
      },
      row: {},
      loading: false
    };
  },
  methods: {
    dayjs,
    loadData() {
      let patrolDeviceId = this.row ? this.row.patrolDeviceId : sessionStorage.getItem('patrolDeviceId')
      this.loading = true
      this.$ajax.post("archivesDevice", {
        pageNum: this.page,
        pageSize: this.size,
        patrolDeviceId: parseInt(patrolDeviceId)
      }).then((res) => {
        sessionStorage.setItem("detail", true);
        this.form = res.data;
        this.loading = false
      }).catch(err=>{
        this.loading = false
      })
    },
  },
  mounted() {
    if (!sessionStorage.getItem('patrolDeviceId')) {
      this.$router.push("/archivRet");
      return;
    }
    this.row = this.$route.params.row
    this.loadData()
  },
};
</script>

<style lang="less" scoped>
.mains {
  height: 100%;
}
/deep/.el-loading-spinner .el-icon-loading{
  font-size: 50px;
}
.form-box {
  padding-top: 5px;
  max-width: 1000px;
}
.img-box {
  .images {
    overflow: hidden;
    background-color: #fff;
    border: 1px solid #c0ccda;
    border-radius: 6px;
    box-sizing: border-box;
    width: 148px;
    height: 148px;
    margin: 0 8px 8px 0;
    display: inline-block;
  }
}
</style>
<style lang="less">
</style>
